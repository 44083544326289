






















import OfficeList from '@/components/Layout/OfficeList.vue'
export default {
    name: 'OfficeSelector',
    components: {
        OfficeList
    }
}
